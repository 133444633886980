import React, { Component } from "react";
import { Layout } from "../../components/Layout/layout";
import Wrapper from "../../components/Layout/Wrapper/wrapper";
import Loading from "../../components/atoms/Loading";
import DotsLoader from "../../components/atoms/DotsLoader";
import Seo from "../../components/utils/SEO";
import { navigate } from "gatsby";
import { MessageBox } from "../../components/molecules/MessageBox";
import { GetTransactionInfoHandler } from "../../services/api/GetTransactionInfo/GetTransactionInfoHandler";
import { GetApplicationInfoHandler } from "../../services/api/GetApplicationInfo/GetApplicationInfoHandler";
import { getCookie, removeCookie } from "../../components/utils/cookies";
import { delay, isInAnIframe } from "../../components/utils";
import "./styles.scss";

class verifyPayment extends Component {
  constructor(props) {
    super(props);

    const orderCookie = getCookie("address_token");

    this.state = {
      gatewayIdentifier: orderCookie.gateway,
      transactionId: orderCookie.transactionId,
      applicationId: orderCookie.order,
      loading: true,
      isInAnIframe: null,
      mounted: false,
    };
  }

  getStatusTransaction = () => {
    return new Promise(async (resolve, reject) => {
      const GetTransactionInfo = new GetTransactionInfoHandler({});
      const data = {
        gatewayIdentifier: this.state.gatewayIdentifier,
        gatewayExternalId: this.state.transactionId,
      };

      let retries = 20;
      let flag = true;
      while (flag) {
        try {
          const res = await GetTransactionInfo.customAction(data);

          if (res.transaction_status === "Success") {
            flag = false;
            return resolve();
          } else if (res.transaction_status === "Failed" || retries === 0) {
            flag = false;
            throw reject();
          }

          retries = retries - 1;
          await delay(4000);
        } catch (error) {
          flag = false;
          throw reject();
        }
      }
    });
  };

  getStatusApplication = () => {
    return new Promise(async (resolve, reject) => {
      const GetApplicationInfo = new GetApplicationInfoHandler({});

      const redirectToThankYou = () => {
        const orderCookie = getCookie("address_token");

        if (orderCookie.urlRedirect) {
          // remove local cookies before redirect
          removeCookie("address_token");
          removeCookie("pdfId");
          removeCookie("app_id");
          removeCookie("order_status");

          window.top.location.href = `${orderCookie.urlOrigin}/thank-you`; // Leave window.top.location.href as is outside of the iframe
        } else {
          window.top.location.href = "/thank-you"; // Leave window.top.location.href as is outside of the iframe
        }
      };

      const data = {
        applicationId: this.state.applicationId,
      };

      let retries = 20;
      let flag = true;
      while (flag) {
        try {
          const response = await GetApplicationInfo.customAction(data);

          if (response.status === "registered" && retries !== 0) {
            retries = retries - 1;
            await delay(2000);
          } else if (response.status === "registered" && retries === 0) {
            flag = false;
            throw reject();
          } else {
            flag = false;
            redirectToThankYou();
            return resolve();
          }
        } catch (error) {
          flag = false;
          throw reject(error);
        }
      }
    });
  };

  verifyOrder = async () => {
    try {
      if (
        this.state.gatewayIdentifier === "macropay" &&
        typeof this.state.transactionId === "string"
      ) {
        await this.getStatusTransaction();
      }

      await this.getStatusApplication();
    } catch (error) {
      console.error("error verifyOrder", error);

      window.top.location.href = "/payment?error=true";
    }
  };

  componentDidMount() {
    if (this.state.applicationId === undefined || this.state.applicationId === "") {
      navigate("/home");
    }

    this.setState({
      isInAnIframe: isInAnIframe(),
      mounted: true,
    });

    this.verifyOrder();
  }

  render() {
    return !this.state.mounted ? (
      <Loading full />
    ) : (
      <>
        {this.state.isInAnIframe ? (
          <Message className="verify-payment verify-payment--inIframe" />
        ) : (
          <Layout
            bgColor={`bg-grey`}
            hideDisclaimer
            hideFooterNavigation>
            <Seo title="Thank you for trusting us" />
            <Message className="verify-payment verify-payment--normal" />
          </Layout>
        )}
      </>
    );
  }
}

const Message = ({ className }) => (
  <Wrapper
    classNames={className}
    small>
    <MessageBox
      title="Please wait while we verify the payment"
      content={
        <>
          <Loading
            message={
              <span>
                Verifying <DotsLoader />
              </span>
            }
          />
        </>
      }
    />
  </Wrapper>
);

export default verifyPayment;
